.WSOPkdg9ruAafxTar3FQa {
  padding-top: 10em;
  min-height: calc(100vh - 25em);
  width: 80%;
  margin: 0 auto;
}

._19-MuThVstIIBN9EtwrrgU {
  background-color: white;
}

.K4IERdLDc6skfon95BRwJ {
  font-size: 19px;
  font-weight: 600;
}
._2gh3kHpHwph48bwcOKK2oN {
  margin: 0.5em 0;
}

._3eJs8yz6OENx0dnGNpq1dc {
  display: inline;
  font-weight: 600;
  font-size: 16px;
}

.PSXvv-tmczgkEczGDhrYh {
  padding: 12px 16px;
  margin-top: 1em;
}

._1DlmF-atta8JwUJU0NnXPB a:link,
._1DlmF-atta8JwUJU0NnXPB a:visited,
.NfmRgpNMmYqoIPFNp8_We {
  color: rgba(0, 0, 0, 0.85);
  font-style: italic;
}

._1DlmF-atta8JwUJU0NnXPB a:hover,
.NfmRgpNMmYqoIPFNp8_We:hover {
  color: #888;
  border-bottom: 1px solid #888;
  cursor: pointer;
}

._1iXYs758Owp1uK7CJCkmJu,
._1iXYs758Owp1uK7CJCkmJu a:link,
._1iXYs758Owp1uK7CJCkmJu a:visited {
  color: rgba(0, 0, 0, 0.85);
  font-style: italic;
}

._1iXYs758Owp1uK7CJCkmJu:hover {
  color: #888;
  border-bottom: 1px solid #888;
  cursor: pointer;
}
