._2bevkEMRJc-GyDMfFDIS-0{
    color: white;
}

._1KlfncUIlLyx-IsPYVZpTn{
    height: 100%;
    position: relative;
}

._19KfPAQguy8CclsOkefmxz{    
    height: fit-content;
    padding: 20px 40px;
    background: rgba(196, 196, 196, 0.10);
    backdrop-filter: blur(1.5px);
    position: absolute;
    width: 100%;
    top: 50%;
    transform: translateY(-50%);
    vertical-align: middle;
}

._19KfPAQguy8CclsOkefmxz > div:first-child{
    color: #FFF;
    text-align: center;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.66);
    font-size: 20px;
    font-style: italic;
    font-weight: 700;
    margin-bottom: 14px;
}

._19KfPAQguy8CclsOkefmxz > div:last-child{
    margin: 0 auto;
    margin-top: 20px;
}
