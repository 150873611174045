._3tfCUl0ZoG_eandKjF5NQl{
    margin: 0 auto;
    width: 66%;
    margin-top: 3em;
    gap: 2em;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    margin-bottom: 2em;
  }

  .mdsyoUkuoNJZUNZfcjqNU {
    margin: 0 auto;
    display: block;
    z-index: 2;
    position: sticky;
    width: 100%;
    padding: 1.5em 3em ;
    top: 139px;
    background: white;
    box-shadow: 0px 15px 10px -15px #919191;    
    
  }

._3ZBkUgY1skcwCoX-wbf4L6{
  grid-area: button;
}

  .dxPlmX5LnHMoj3-N3O-Rb > div{
  margin-bottom:0px ;
}

._1cR3MvSc3ICjgLAFS3wGC6:hover{
  cursor: pointer;
  margin-top: 1em;
}._1cR3MvSc3ICjgLAFS3wGC6{
  margin-top: 1em;
}

  .mdsyoUkuoNJZUNZfcjqNU > div  {
    justify-content: end;
    display: flex;
    margin: 0 auto;
    max-width: 66em;
    align-items: center;
    gap: 1em;
    position: relative
  }
  
  ._1srSclb8Uh7DNHJiNttjpF{
    text-align: center;
    white-space: pre;
  }

._1eDYNd_ya59UccnNXuXIuE{
  display: flex;
  gap: 1em;
  justify-content: center;
  align-items: center;
  position: absolute;
  left: 0;
  grid-area: people;
}

._3ZHVGGdfPnf4uKBqcEDnqH{
  font-size: 20px;
  font-weight: 550;
  font-style: italic;
  float: left;
  margin: 0;
  margin-right: 1em;
  grid-area: price;
}

._3ZHVGGdfPnf4uKBqcEDnqH > div {  
  color: rgba(0, 0, 0, 0.45);
  font-size: 13px;
  font-weight: 400;
  font-style: italic;
  margin-top: -0.5em;
}

._2TUfQTz9O1HS_2BQX6M3Kf{
  text-align: end;
  grid-area: info;
  align-content: center;
}

  ._3EU5Dmr-3gCbrJVmqxOjsb,
  .dxPlmX5LnHMoj3-N3O-Rb{
    width: 50em;
    margin: 0 auto;
    }

    ._3VTMSUPWvx7QINLZ8fQbVq{
      margin: 0 0.5em;
      font-size: 16px;
    }

@media only screen and (max-width: 1400px) {
    ._3tfCUl0ZoG_eandKjF5NQl{
      grid-template-columns: 1fr 1fr;
  
    }
  }

@media only screen and (max-width: 1000px) {

.mdsyoUkuoNJZUNZfcjqNU{
  
  padding: 1em 1em ;
}

.mdsyoUkuoNJZUNZfcjqNU > div {
  display: grid;
  grid-template-areas:
  "people price button"
  "- info info"; 
  width: 100%;
  margin-right: 1em;
  grid-template-columns: 1fr auto auto;
  grid-gap: 1em;
}
._1eDYNd_ya59UccnNXuXIuE{
  gap: 0.5em;
  display: block;
  margin-left: 0.5em;
  
}

._3ZHVGGdfPnf4uKBqcEDnqH{
  justify-self: end;
  margin-right: 0;
}



._1ohk5Jtzrtvt-9UF6YdbDv{
  display: none;
}

    ._3tfCUl0ZoG_eandKjF5NQl{
        grid-template-columns: 1fr;
        width: 100%;
        overflow: hidden;

    }


    ._3EU5Dmr-3gCbrJVmqxOjsb,
    .dxPlmX5LnHMoj3-N3O-Rb{
      width: 100%;
    
    }
    ._1srSclb8Uh7DNHJiNttjpF{
      text-align: center;
      white-space: normal;
      margin: 0 1em;
    }
}
  