._1Ztx5QyyaW4ZobHmQOienh {
  overflow: hidden;
  width: 100%;
  height: 100vh;
  background-color: #282828;
}

._2ljDOuHRDKIy3bP7THMmRA {
  margin: auto;
  display: inline-block;
  height: 100vh;
  min-width: 100%;
  z-index: 98;
  position: relative;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  transition: height 0.4s linear;
}

._1Jc3ilnbXDE9wg9di1rqpP {
  z-index: 99;
  position: absolute;
  top: 50vh;
  left: 50%;
  transform: translate(-50%, -50%);
}

._2cPSHyHJvaO8AMTslPmx_b {
  
  text-shadow: 1px 2px 11px black;
  color: white;
  font-size: 64px;
  width: 100%;
  position: relative;
  margin: 0px auto;
  text-align: center;
  font-style: italic;
  padding-top: 1em;
  font-weight: 550;
}

._1LcYj_ZamhfcgEySE_X-8c {
  text-shadow: 1px 2px 11px black;
  font-size: 24px;
  text-align: center;
  margin-top: 0.25em;
  color: white;
}

._4yEmW4Mfxq2FLjB0BbbY8 {
  margin: 2em auto;
  width: fit-content;
}

@media only screen and (max-width: 900px) {
  ._2cPSHyHJvaO8AMTslPmx_b {
    font-size: 36px;
  }
  ._1LcYj_ZamhfcgEySE_X-8c {
    font-size: 17px;
  }
}
