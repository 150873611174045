._2Cf4ZlUOdhX2jTsBGYeRwn {
  font-weight: 550;
  font-size: 16px;
  color: rgb(207, 207, 207);;
}

.dFePuJuazYM7mddyBx65J{
  color: #888
}

._2fa4HOuk3wnpSbyjKOu25D {
  font-size: 20px;
}


.dFePuJuazYM7mddyBx65J a:link,
.dFePuJuazYM7mddyBx65J a:visited{
  color: #888;
  border-bottom: 1px solid #888;
}

.dFePuJuazYM7mddyBx65J a:hover{
  color: rgb(207, 207, 207);
  border-bottom: 1px solid rgb(207, 207, 207);
  cursor: pointer;
}

