._1lFOa4rRnJHWtR9ZpwXctc {
  min-height: 20em;
  background-color: #282828;
  padding-bottom: 3em;
}

._22Wf60K3jIE4AwleOUJFWU{
  display: grid;
  grid-template-columns: auto auto;
  grid-gap: 4em;
  min-height: 31em;
  max-width: 66em;
  margin: 0 auto;
}

._1TzPPKadvu0YRQRg4cac4T {
  color: white;
  font-size: 30px;
  width: 100%;
  position: relative;
  margin: 0px auto;
  text-align: center;
  font-style: italic;
  padding-top: 1em;
}

.qOsn7zk-pI9AUv3o-47Mq{
  position: relative;
  min-width: 200px;
}

.KEsddWyyU9XV5L93EB-6p{
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 2em;
  padding-top: 3em;
  padding-bottom: 3em;
}

._3yymi4P65X0ImOupDJeCrj{
  position: absolute;
  right: 50vw;
}

._1IZuB0WHE5-sclLhVjEeKX{  
  animation: iSwTeHAL1EdBS-rePbxps 1.5s ease-out forwards;  
  animation-fill-mode: both;
  animation-play-state: running;
}


@keyframes iSwTeHAL1EdBS-rePbxps {
 from  {right: 50vw}
  to  {right: 0%}
}


@media only screen and (max-width: 1000px) {
  .KEsddWyyU9XV5L93EB-6p {    
    grid-template-columns: 1fr;
  }
  ._22Wf60K3jIE4AwleOUJFWU {    
    margin-right: 4em;
    
  }
}

@media only screen and (max-width: 600px) {
  ._22Wf60K3jIE4AwleOUJFWU {    
    grid-template-columns: 0 auto;
    margin-right: 4em;
    
  }
  ._3yymi4P65X0ImOupDJeCrj{
    display: none;
  }
}
