._1eIZI1w-vSH6aIxHpQBVWM{
  padding-top: 8em;
  min-height: 80vh;
  max-width: 50em;
  margin: 0 auto;
  padding-bottom: 4em;
}

._2PhchjbhyR7S6QU92DRGQn tr td {
  vertical-align: top;
  text-align: left;
  padding-right: 2em;
}

._1Qv6ReKakTHZ89qFCMcYVy, h4{
  font-weight: 550;
  font-size: 16px;
}

li> h4{
  font-size: 14px;
}


.HvlXtsUhlTUsAnGhWVxkX{
  font-size: 20px;
  color: rgb(51, 51, 51);
}

._2PhchjbhyR7S6QU92DRGQn{
  width: fit-content;
  margin: 0 auto;
}

._2pLyQlHeE9iwRGVxVBJqEy > ul {
  list-style-type: none !important;
}

._2pLyQlHeE9iwRGVxVBJqEy{
  
  padding: 0 10px;
}