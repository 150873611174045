._3StYX1LDtKNjZtqHoHi8iG {
  color: rgb(51, 51, 51);
  font-style: italic;
  font-size: 22px;
}

._1_vKPfOdNb8JjVem4Y0p3o {
  margin-top: 2em;
}

._8HWXascTpN_VOHjhoMUC9 {
  background-color: #a73c3c;
  height: -moz-fit-content;
  height: fit-content;
  padding: 24px;
  color: white;
}

._2Oq1eN-pUtimekwIA7lMLN {
  display: grid;
  grid-template-columns: 2em auto;
}

._3pFP8Cmf-Ash1PkkdypS-7 {
  text-align: center;
  font-size: 20px;
}

._3vTNCvJnem3KIgcWGtmq4 {
  font-weight: 600;
}

.bF9oZ2gNP01QLxXiWEJmq {
  width: 70%;
  margin: 0 auto;
}

._3ed_OHHbVNG_6XZd1Pufpc {
  width: fit-content;
  margin-left: auto;
  margin-right: 0;
}

._2wxlxcy6dOxScvtW_lQSuy{
  padding-top: 11em;
}

@media only screen and (max-width: 1000px) {
  ._8HWXascTpN_VOHjhoMUC9 {
    padding: 24px 6px;
  }
  .bF9oZ2gNP01QLxXiWEJmq {
    width: 100%;
  }
}
