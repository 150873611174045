._1iT1H3cKMNZYSrtZOh_mo3 {
  margin: 0 auto;
  display: block;
  width: 66em;
}

.olUeOFqSm6hCjQYc38tad {
  height: 29em;
  width: 100%;
  margin: 0 auto;
  position: relative;
  margin-bottom: 1em;

}

._2os6JEx6eYTJW4Qz9HgBYA{
  
  padding-top: 4em;
  padding-bottom: 4em;
}

._1U_iW8o_d_HPfX4cNcHhvA{
  margin: 0 auto;
  margin-top: 3.8em;
  width: 66em;
}

._1jbGe1GFgAnLyf45KNv84Q{
  float: right;
  font-size: 1.5em;
}


._1R6BoXGRs8M7SWIwXLeoBB{

height: 100%;
}

._1aHQFjwzunhuOMPhi3ccjm{

  width: 100%;
  display: inline-block;
  height: 100%;
  object-fit: cover;
  overflow: hidden;
}
.nmrMxSo-NHbUrXJSEiHFC {
  position: relative;
  max-width: 66em;
  margin: 0 auto;
  margin-top: -3.3em;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 2em;
}

.g13UmOfNTOdf2Eomlu8D4 {
  width: 50%;
}

._3Xyua-GC_LOvL-NIC2BaHJ {
  width: 100%;
  margin: 0 auto;
  padding: 5em 0px 1em;
}

._2JLJgln2Wbz7tb1D9R_iv8:hover {
  cursor: pointer;
  background-color: white;
}

._2JLJgln2Wbz7tb1D9R_iv8 {
  padding: 0.2em 1.5em;
  transform: skew(-16deg);
  height: 3.4em;
  background-color: #cfcfcf75;
}

._1wSDzxhDwWLh26nuZ8UMqd {
  position: relative;
  max-width: 36em;
  margin: 0 auto;
  margin-top: -3.3em;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 2em;
}

._3EEPjheagelnDXcjxflrNW {
  background-color: white;
}

._3EEPjheagelnDXcjxflrNW:hover{

  background-color: white;
}


._13ke9XJgQla_ven2Sv8lqk {
  font-weight: 500;
  font-size: 18px;
  color: rgb(51, 51, 51);
  text-align: center;
  justify-self: center;
  height: 100%;
  width: fit-content;
  margin: 0.4em auto;
  transform: skew(16deg);
}






@media only screen and (max-width: 1000px) {
  ._1iT1H3cKMNZYSrtZOh_mo3 {
    width: 100%;
    padding-top: -2em;
  }

  .nmrMxSo-NHbUrXJSEiHFC {
    position: relative;
    margin: 0 auto;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 1em;
    width: 100%;
    padding-left: 1em;
    padding-right: 1em;
    margin-top: -2em;
  }

  ._1wSDzxhDwWLh26nuZ8UMqd {
    height: 2em;
  }

  ._13ke9XJgQla_ven2Sv8lqk {
    font-size: 13px;
  }

  ._1wSDzxhDwWLh26nuZ8UMqd {
    padding: 0.2em 0.5em;
  }

  ._1U_iW8o_d_HPfX4cNcHhvA{
    width: 100%;
       margin-left: 0.5em;
  }


}
