._2LV5Ra7H59_OXnExgiwbW1 {
  font-size: 20px;
}

._3B-uv89XTZROYBmISAqFJC{
  color: #888;
  display: grid;
  grid-template-columns: 35px auto;
  margin: 1em;
}

._3RJGpWXJEPxZpIB0-OlVsb {
  vertical-align: top;
  display: inline;
}

._1kh7epdrpXHwEe8CRSNsKb {
  color: #333;
}
