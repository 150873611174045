
._1_Si66xcbjFrZchx4m-4rq{
    display: flex;
    flex-direction: column;
    gap: 1em;
    justify-content: stretch;
    margin-top: 2em;
}

._1_Si66xcbjFrZchx4m-4rq > div {
    width: 100%;
}

._1tFaRZJYL0Tqs7zBZxQhDI{
    padding-top: 3em;
}

._1AbNr5ewhfK_kTjPgGGfVk{
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 1em;
    margin: 0 auto;
    width: fit-content;
    margin-top: 1em;
}

._2fIMGQY78OTAxMUagHEeyT:link,
._2fIMGQY78OTAxMUagHEeyT:visited {
  color: #888;
  border-bottom: 1px solid #888;
}

._2fIMGQY78OTAxMUagHEeyT{
    color: #888;    
    border-bottom: 1px solid #888;
    width: fit-content;
}

.vRr5-bM8BHawqKCMEcM_O{
    float: right;
}


._2q3YCTgzuvIW04Yty59RgC{    
    font-size: 25px;
    margin-bottom: 1em;
}

._1CXiqLhIv0n2UT7OvNLrS_{
    
    white-space: break-spaces;
}