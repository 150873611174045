._34t3Jt3ER-e3UB3qru5QYY {
  grid-template-columns: 28em 28em 28em;
  display: grid;
  grid-gap: 3em;
  margin: 0 auto;
  margin-bottom: 3em;
  width: 90em;
}

._1U--r9WN6GCCLxUbgR7Qaj {
  width: 100%;
}

@media only screen and (max-width: 1300px) {
  ._34t3Jt3ER-e3UB3qru5QYY {
    grid-template-columns: 22em 22em 22em;
    width: 72em;
  }
}

@media only screen and (max-width: 1100px) {
  ._34t3Jt3ER-e3UB3qru5QYY {
    align-content: center;
    margin-bottom: 7em;
    display: block;
    width: 100%;
  }
}
