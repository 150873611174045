._1QXkD5cR39j8afJ4tuu5Xv{
  min-height: 80vh;
  max-width: 50em;
  margin: 0 auto;
  padding: 10em 1em 5em;
}
._3nixMQewCRxFZihw6f280x{
  margin-left: 2em;
}

._3oXzHpVvqxutPyf3vpzBTW{
  float: right;
}

.iiQb-Kw-3rqerqDZHf0jf{
  font-weight: 550;
  font-size: 14px;
}

._1BgvokGBv7HvBEL8fNrYb4{
  display: grid;
  grid-template-columns: 1fr auto;
  align-items: center;
  gap: 2em;
  width: 100%;
}

._1n59zUlMDXSxJMThsVQrX8 {
  margin-top: 4px;
  display: inline-block;
  padding-right: 0.5em;
}

._1_FjEzsc2io9rei3BYkFkX{
  text-align: start;
}