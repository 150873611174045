._3F9-CCzQDwE96SYJk2xeBG,
._3pg-wSOy6bJF8cNfq7INIw,
._2-5jvfXf_gyXKHiFylw0A2,
.KAAw3y9DadsNUEQpkLE8I{
  border: 1px solid white;
  padding: 0.2em 1.5em;
  transform: skew(-16deg);
  min-width: 3em;
  min-height: 1em;
  width: fit-content;
}


._3F9-CCzQDwE96SYJk2xeBG:hover,
._3pg-wSOy6bJF8cNfq7INIw:hover,
._2-5jvfXf_gyXKHiFylw0A2:hover,
.KAAw3y9DadsNUEQpkLE8I:hover {
  cursor: pointer;
}

._3S4lDIWK6Knta3XJTLO5YM:hover {
  cursor: not-allowed;
}

._3S4lDIWK6Knta3XJTLO5YM {
  color: #00000040;
  background-color: #f5f5f5;
  border: 1px solid #f5f5f5;
  padding: 0.2em 1.5em;
  transform: skew(-16deg);
  min-width: 3em;
  min-height: 1em;
  width: fit-content;
}

._3F9-CCzQDwE96SYJk2xeBG > div,
._3pg-wSOy6bJF8cNfq7INIw> div,
._2-5jvfXf_gyXKHiFylw0A2> div,
.KAAw3y9DadsNUEQpkLE8I> div,
._3S4lDIWK6Knta3XJTLO5YM> div {
  transform: skew(16deg);
  font-size: 13px;
  text-align: center;
}

._3F9-CCzQDwE96SYJk2xeBG:hover,
._3pg-wSOy6bJF8cNfq7INIw {
  background-color: #333  !important;
}
._3F9-CCzQDwE96SYJk2xeBG:hover > div,
._3pg-wSOy6bJF8cNfq7INIw > div{
  color: white;
}

._2-5jvfXf_gyXKHiFylw0A2:hover,
.KAAw3y9DadsNUEQpkLE8I {
  background-color: white;
}
._2-5jvfXf_gyXKHiFylw0A2:hover > div,
.KAAw3y9DadsNUEQpkLE8I > div {
  color: #333;
}

._3pg-wSOy6bJF8cNfq7INIw:hover{  
  background-color: #242424;
  border-color: #242424;
}
.KAAw3y9DadsNUEQpkLE8I:hover {  
  background-color: #e6e6e6;
  border-color: #e6e6e6;
}

._2-5jvfXf_gyXKHiFylw0A2 {
  border-color: white;
  color: white;
  text-align: center;
}

._3F9-CCzQDwE96SYJk2xeBG{
  border-color: #d9d9d9;
  color: #333;
  text-align: center;
}

._2om0DM7yj853r7yZhRcfU2 {
  text-align: center;
  padding: 4px 30px;
}
._2om0DM7yj853r7yZhRcfU2> div {
  font-size: 20px;
}
