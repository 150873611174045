.mDISttACIeKtSiMS1Z3Ko {
  align-content: center;
  padding-bottom: 3em;
}

._1loSocvB8pM723OjpgsUQL {
  position: relative;
  margin: 1em auto;
  max-width: 600px;
  width: 100%;
}

._22Yc2_W0aQQY97ZBq2u1tE {
  display: grid;
  grid-template-columns: 600px 20em;
  grid-gap: 2em;
  margin: 0 auto;
  max-width: calc(600px + 20em + 2em);
}

._28vTIWG1U0RDBmVZtH1CJ3 {
  margin-top: 1em;
}

@media only screen and (max-width: 900px) {
  ._22Yc2_W0aQQY97ZBq2u1tE {
    grid-template-columns: 100%;
    grid-template-rows: auto auto;
    align-content: center;
    position: relative;
    width: 80%;
  }
}
