._3th90JMCrsXSgOLY9jQdUj {
  min-height: 20em;
  background-color: #5A7B5D;
  padding-bottom: 3em;
  color: rgb(207, 207, 207);;
}

._2eQiufEFbbFeveZBL2wgOP {
  width: 60em;
  margin: 0 auto;
  display: grid;
  grid-template-columns: auto auto;
  grid-gap: 3em;
}


._3O60jDKiApUeRVXMHl8YgK {
  color: rgb(207, 207, 207);
  border-bottom: 1px solid rgb(207, 207, 207);
}

._3O60jDKiApUeRVXMHl8YgK:hover {
  cursor: pointer;
  color: white;
  border-bottom: 1px solid white;
}

@media only screen and (max-width: 900px) {
  ._2eQiufEFbbFeveZBL2wgOP {
    width: 80%;
    grid-template-columns: auto;
  }
}
