._2javqx5wNpyF6nV4Dgb1AS {
  min-height: 12em;
  padding-bottom: 3em;
  text-align: center;
}

._2javqx5wNpyF6nV4Dgb1AS > div:last-child{
  max-width: 53em;
  margin: 0 auto;
}

._2javqx5wNpyF6nV4Dgb1AS > div:first-child{
  font-weight: 550;
  font-size: 16px;
}

._2javqx5wNpyF6nV4Dgb1AS > div{
  white-space: pre-wrap;
}

@media only screen and (max-width: 900px) {
  ._2iE8UYp525tIyaiECRQLea {
    align-content: center;
    grid-template: "left" "right";
    width: 92%;
    justify-self: center;
    margin: 0 auto;
  }

}