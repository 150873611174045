._33YcK51XMCjLISRdXGLrfs{
    flex-basis: 31%;
    height: fit-content;
    flex-grow: 4;

}

._2h3vMdDCYW7mExoUmdfXdb{
  margin-right: 0.5em;
}

._3NZp5n3NSyALUwz7a1_NgV{
  background:  radial-gradient(ellipse farthest-corner at right bottom, #FEDB37 0%, #FDB931 8%, #9f7928 30%, #8A6E2F 40%, transparent 80%), radial-gradient(ellipse farthest-corner at left top, #FFFFFF 0%, #FFFFAC 8%, #D1B464 25%, #5d4a1f 62.5%, #5d4a1f 100%) !important; 
}
._35zaXKm8l26PZIxYdZ_IdO{
  background:  radial-gradient(ellipse farthest-corner at right bottom, #cfcfcf 0%, #444444 8%, #727272 30%, #74726c 40%, transparent 80%), radial-gradient(ellipse farthest-corner at left top, #FFFFFF 0%, #ffffff 8%, #bbbbbb 25%, #727272 62.5%, #6e6e6e 100%) !important;
} 
.SmWnoWxtakYaEDRTIkYIx{
  background:  radial-gradient(ellipse farthest-corner at right bottom, #d1d1d1 0%, #7a3818 8%, #b77838 30%, #c58c6b 40%, transparent 80%), radial-gradient(ellipse farthest-corner at left top, #FFFFFF 0%, #e7a97d 8%, #b38357 25%, #d57944 62.5%, #482e12 100%) !important;
}

._2pDpb1v2fL9rbhg9BdWZ8K:hover{
  cursor: pointer;
}

._3zcBgH3MFNlZw131F1Ksqq{
    object-fit: cover;
    width: 100%;
    height: 100%;
    vertical-align: middle;

    
    opacity: 1;
    display: block;
    transition: .5s ease;
    backface-visibility: hidden;
}
.TIdaEtZihh66xJ1ymQ-Gi{
    transition: .5s ease;
    /*opacity: 0;
    position: absolute;
    top: 0;*/
    white-space: pre-wrap;
    width: 100%;
    padding: 1em;
}

._2H4MjYn2PpRqXHUmHtlPrj{    
  position: relative;
}
._2H4MjYn2PpRqXHUmHtlPrj:hover {
  cursor: pointer;
}

.EXh5vjNAK6n0nKnSxJ2Vz{
  display: grid;
  grid-template-areas: "name button" "caliber button";
}

._2H4MjYn2PpRqXHUmHtlPrj:hover,
.kS9tk_DQcSchL7GPcdhez:hover,
._2xlqMnITw3qAgoMFRWjlMa:hover{
  cursor: pointer;
}
._2xlqMnITw3qAgoMFRWjlMa{
  
}

/*
.ImgContainer:hover .weapnImg {
    opacity: 0.3;
  }
  
  .ImgContainer:hover .overlayImg {
    opacity: 1;
  }
  **/

._3cNrOAdKGdaCB9QRIjSzJy{
    float: right;
    display: inline-block;
    margin-right: 4px;
    grid-area: button;
    justify-self: end;
    align-self: end;
    margin-bottom: 2px;
}

.kS9tk_DQcSchL7GPcdhez{
    margin-top: 1em;
    display: inline-block;
    font-weight: 550;
    font-size: 16px;
    color: rgb(51, 51, 51);
    grid-area: name;
}

._30reMA24wD7B7_3ZV1ZXqF{
  grid-area: caliber;
}

.V7Mtsvt83DaaMY-tCI7pC{
  font-weight: 550;
  font-size: 16px;
  color: rgb(51, 51, 51);

}

._1Hi4UFwKC91p3rccdxnQPf{
  min-width: 40%;
}

@media only screen and (max-width: 1000px) {


  .EXh5vjNAK6n0nKnSxJ2Vz{
    margin: 0 1em;
  }
}