.KKUI0MS0AQUBRN51ZRYB6{
    animation-fill-mode: both;
    animation-play-state: paused;
    overflow: hidden;
}

._3__TbV3Hsfdyy1Ms0JX4E_{
    animation: -DxMGQJu6rfrTdiFWOP2U 2s .1s forwards;  
    animation-fill-mode: both;
    animation-play-state: paused;

}

._3poz2WYqB2QUa5M3cA_Wj-{
    animation: _3DEe5YvLLlbVQvMQwSbDMY 2s .1s forwards;  
    animation-fill-mode: both;
    animation-play-state: paused;
    
}

._3AAyLNTJCmzyUTWIAWxFxh{  
    animation-play-state:running;
  }  
  
  @keyframes -DxMGQJu6rfrTdiFWOP2U {
   from  {margin-left: 5em; opacity: 0;}
    to  {margin-left: 0; opacity: 1;}
  }

  @keyframes _3DEe5YvLLlbVQvMQwSbDMY {
    from  {margin-right: 5em; opacity: 0;}
     to  {margin-right: 0; opacity: 1;}
   }

   ._3vtvsZgOhP23459dcIYQlt{
      font-weight: 550;
   }