._2PA-t3xGfzKmNhBp4svDAz {
  display: flex;
  column-gap: 3em;
  row-gap: 1em;
  padding: 3em 0;
  flex-direction: row;
  flex-wrap: wrap;
  flex-basis: 50%;
  align-items: strech;
  align-content: stretch;
}

._3Xo7Ln3lU9nDogQR0LtXHF {
  width: 100%;
  max-width: 43%;
  aspect-ratio: 4 / 3;
}

._1Fzd-6fxk_ctuRUhnQqh-q {
  width: 100%;
  aspect-ratio: 4 / 3;
}

._3Xo7Ln3lU9nDogQR0LtXHF:hover {
  cursor: pointer;
}

.zY2CCDLOomQi8_d3uI4U2 {
  
  flex-direction: row;
  flex-wrap: wrap;
  flex-basis: 50%;
}

._1MbEE59OSX4PzYVY3liGHY {
  
  flex-direction: row-reverse;
}
.VTh56E7ME0hmAoLKI0Svr{
  white-space: pre-wrap;
}

._1Nl-B2zG2P8lTPKPzD4xb4 {
  width: 100%;
  height: 100%;
  max-width: 100%;
}

._1Cl66fa1E-5QkFv4F5s4xy {
  color: #333;
  font-size: 20px;
  padding-right: 15px;
}

._1TXY3sIXm0qM9BZnBipKie {
  color: #333;
  display: grid;
  grid-template-columns: 35px auto;
}

._3Jv3m0Nb11cf1na1YL2OSF {
  vertical-align: top;
  display: inline;
}

._27yX2bNSOnOt6U_MgfqF5Y {
  margin: 0.5em 0;
}

._1L9rwyA7P-nZqryftvQ1Tr {
  /*width: 100%;*/
  flex-grow: 1;
  position: relative;
  grid-area: info;
  display: flex;
  flex-direction: column;  
}

.U6M7vXNd6L9nMHf0YUEuu {
  position: relative;
  float: right;
  bottom: 7px;
  right: 0;
  width: fit-content;
}

._39Q2PHFQQDM7ZQo3jiqSip {
  position: absolute;
  font-size: 28px;
  font-weight: 400;
  font-style: italic;
  bottom: -2px;
}

.uiLqKAXXypu6ZKwSkO6Fa{
  position: relative;
  bottom: -25px; 
  color:  rgba(0, 0, 0, 0.45);
  font-size: 13px;
  font-weight: 400;
  font-style: italic;
  display: inline-flex;
  gap: 0 6px
}

._3bdP_sh-1i29DKGwInzXCo {
  height: 3em;
  justify-content: center;
  position: relative;
  margin-top: 2em;
  flex-grow: 1;
}

._2SShYQUuRC_fUBZdnx8Jff {
  position: absolute;
  bottom: 0;
  width: 100%;
}

@media only screen and (max-width: 1000px) {
  ._2PA-t3xGfzKmNhBp4svDAz {
    width: 100%;
    height: 100%;
    margin: 0 auto;
  }
  ._3Xo7Ln3lU9nDogQR0LtXHF {
    width: 100vw;
    max-width: 100%;
    aspect-ratio: 4 / 3;
    display: block;
  }

  ._1L9rwyA7P-nZqryftvQ1Tr {
    padding: 0 4em;
  }

  ._39Q2PHFQQDM7ZQo3jiqSip {
    bottom: 10px;
  }
  .uiLqKAXXypu6ZKwSkO6Fa{
    bottom: -10px; 
  }

  .U6M7vXNd6L9nMHf0YUEuu{
    bottom: 18px;
  }

}

@media only screen and (max-width: 450px) {
  .U6M7vXNd6L9nMHf0YUEuu{
    bottom: -60px;
  }
 
  
  .uiLqKAXXypu6ZKwSkO6Fa{
    bottom: -5px; 
  }
}

@media only screen and (max-width: 320px) {
  .U6M7vXNd6L9nMHf0YUEuu{
    bottom: -100px;
  }
 
  
  .uiLqKAXXypu6ZKwSkO6Fa{
    bottom: -17px; 
  }
}