._1ZC7rRjHBlZR-RuaIEdIKA{
  padding-top: 8em;
  min-height: 80vh;
  max-width: 50em;
  margin: 0 auto;
}

._1m_m3oBpnLPL-uYS3tnq1P tr td {
  vertical-align: top;
  text-align: left;
  padding-right: 2em;
}

._1m_m3oBpnLPL-uYS3tnq1P tr td:first-child{
  font-weight: 550;
}

.GrrYGPVXe0HXt6KCMoSoV{
  font-size: 20px;
  color: rgb(51, 51, 51);
}

._1m_m3oBpnLPL-uYS3tnq1P{
  width: fit-content;
  margin: 0 auto;
}