._2DJwQEV1Bv_YqwKhmgLAvV{
    width: fit-content;
    margin: 0 auto;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    margin-bottom: 3em;
    grid-gap: 3em ;
}

.nnP92z_-wcSeeZOJersrH{
    background-color: #5A7B5D;
    padding-bottom: 3em;
}

@media only screen and (max-width: 900px) {
    ._2DJwQEV1Bv_YqwKhmgLAvV {    
        width: fit-content;      
        grid-template-columns: 1fr;
    }
  }