._3-eAH3N6xbkflx-vKxGuiq{
        max-width: 80em;
        margin: 0 auto;
}

._3d7_iX_Cf4mx0208_d2oVA{
    margin-top: 3em;
    margin-bottom: 3em;
}

