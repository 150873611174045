.gpPetIYW313swpWaJsGMu {
  color: #cfcfcf;
  font-size: 30px;
  width: 100%;
  position: relative;
  margin: 0px auto;
  text-align: center;
  font-style: italic;
  padding-top: 1em;
  font-weight: 550;
}

._1LD6fn_OomMBb0KyFKHZhn {
  font-size: 20px;
  text-align: center;
  margin-top: 1em;
}

._30sNdOHW8uGQHqpGrGdFlM {
  margin-bottom: 2em;
}

@media only screen and (max-width: 400px) {
  .gpPetIYW313swpWaJsGMu {
    font-size: 24px;
  }
}
