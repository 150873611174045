._1xx-il08khpNsIBjkPfSeg {
  color: rgb(51, 51, 51);
  font-style: italic;
  font-size: 22px;
}

._36KECVYjkR6IVjov6NfAhU{
  font-size: 12px;
  margin-top: 1em;
}

._3WneHI0zrIkpuP4RTbamj8{
  color: black;
  font-style: italic;
}

._3aWGkqWfcGzj3-4zb0p8Nl{
  background:  radial-gradient(ellipse farthest-corner at right bottom, #FEDB37 0%, #FDB931 8%, #9f7928 30%, #8A6E2F 40%, transparent 80%), radial-gradient(ellipse farthest-corner at left top, #FFFFFF 0%, #FFFFAC 8%, #D1B464 25%, #5d4a1f 62.5%, #5d4a1f 100%) !important; 
}
._10abAG6ohG0-9LpaY_sXJ8{
  background:  radial-gradient(ellipse farthest-corner at right bottom, #cfcfcf 0%, #444444 8%, #727272 30%, #74726c 40%, transparent 80%), radial-gradient(ellipse farthest-corner at left top, #FFFFFF 0%, #ffffff 8%, #bbbbbb 25%, #727272 62.5%, #6e6e6e 100%) !important;
} 
._3iDejLjM_BqtsSJII4e33A{
  background:  radial-gradient(ellipse farthest-corner at right bottom, #d1d1d1 0%, #7a3818 8%, #b77838 30%, #c58c6b 40%, transparent 80%), radial-gradient(ellipse farthest-corner at left top, #FFFFFF 0%, #e7a97d 8%, #b38357 25%, #d57944 62.5%, #482e12 100%) !important;
}

._10UHltySt2GleZw0AcTFIv{
  width: 100%;
  padding: 0 5em;
}
._10UHltySt2GleZw0AcTFIv > tr > td:nth-child(2) {
  text-align: end;
  width: fit-content;
}
._10UHltySt2GleZw0AcTFIv > tr:nth-child(5) > td:nth-child(2){
  border-top: 1px solid rgba(0, 0, 0, 0.06);
  font-weight: 550;
}._10UHltySt2GleZw0AcTFIv > tr:nth-child(5) > td:nth-child(1){
  font-weight: 550;
}

._1lIgIasUYfhFMvZz7uJOTR{
  width: 45%;
}

.IzynNmd3HThWg9t64_XSp {
  margin-top: 2em;
}

._28MB_vGwBCJym0gOUytkpj {
  background-color: #a73c3c;
  height: -moz-fit-content;
  height: fit-content;
  padding: 24px;
  color: white;
}
._1AuVJ0yuVQ0d3aZwKLih2P:hover{
  cursor: pointer;
}
._3Qq-1T2eSm3E_CE-vEPOYe {
  display: grid;
  grid-template-columns: 2em auto;
}
._3_okBqOLZohaswgmkH2Skc{
  width: 170px;
  aspect-ratio: 4/3;
}


.Pk3JjYxOw32h6agu8gSl- {
  text-align: center;
  font-size: 20px;
}

.oh_-H_kesQ4BCpAeQmSpV {
  font-weight: 600;
}

._1khSGHC9siI_MrYHtlkV8_ {
  width: 50%;
  margin-left: 5%;
  
}

._2QKPAilmNmMixSgsvEtm6Y{
  display: flex;
}

.CkjUHuYlPDgwbHnzroS-9 {
  width: fit-content;
  margin-left: auto;
  margin-right: 0;
}

._2ITx_7GtQumnlDQ_b5_cMy{
  width: 45%;
  display: inline-block;
  float: left;
}



._2wv3K--undKeL1lJSiqWlm{
  padding-top: 13em;
}
@media only screen and (max-width: 1000px) {
  ._28MB_vGwBCJym0gOUytkpj {
    padding: 24px 6px;
  }
  ._1khSGHC9siI_MrYHtlkV8_ {
    width: 100%;
    margin-left: 0;
  }

  ._2ITx_7GtQumnlDQ_b5_cMy{
    width: 100%;
    display: inline-block;
    float: unset;
  }

  ._1gP08uY0O3P-qfUiOiEPuP{
    justify-content: end;
    display: flex;
    width: 100%;
    margin-right: 1em;
  }

  ._1x2rphD-wi_6mwjtx2GJoW{
    margin-top: -4em;
  }
}

@media only screen and (max-width: 550px) {
  
  ._1x2rphD-wi_6mwjtx2GJoW{
    margin-top: 0;
  }
  ._3_okBqOLZohaswgmkH2Skc{
    width: 120px;
    aspect-ratio: 4/3;
  }
}
