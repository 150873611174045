.CXnfzeLSd9bivLbILnULm {
  display: grid;
  grid-template-areas:"header header" "bigImg info" "smallImg info";
  grid-template-columns: 44em 21em;
  grid-template-rows: auto;
  grid-gap: 2em;
  margin: 0 auto;
  justify-content: center;
  margin-bottom: 3em;
  padding-top: 2em;
}

._3hxHcObIQMCphJXRMEMhhu{  
  grid-area: header;
}

._3xXeNKGji5Qrchoz_KMSVj:hover {
  color:  rgba(0, 0, 0, 0.85) !important;
}

/* Details Image Start*/

._1sy89I33zm7r6XFBTMxlzK {
  width: 44em;
  height: 33em;
}



._1jqwL5BTvNDLGwDiPW0tyU {
  align-content: center;
  display: grid;
  grid-template-columns: 8em;
  grid-gap: 1em;
  width: 8em;
  grid-area: bigImg;
}

._149enfyqsePNZmimZ5Z_Kx {
  object-fit: cover;
  width: 8em;
  height: 6em;
  position: relative;
  margin: 0 auto;
}

._149enfyqsePNZmimZ5Z_Kx:hover {
  cursor: pointer;
  filter: drop-shadow(2px 2px 4px #888);
}

.gVVj8lgyHa1ASdsfaAr16 {
  border: 1px solid #555;
}

._1jqwL5BTvNDLGwDiPW0tyU {
  align-content: center;
  display: grid !important;
  grid-template-columns: repeat(5, 8em);
  grid-gap: 1em;
  width: 44em;
  grid-area: smallImg;
}

/* Details Text*/

._344zKFNfv5DcoaWZqUhkbb {
  grid-area: info;
  width: 21em;
  position: relative;
  margin-top: -3em;
}

._3T1Fll0Vj_2EW1aRsJvutY {
  padding: 0.1em 0;
  white-space: pre-wrap;
}

._21Jsk5lQid7lm2u2pEKYaB {
  color: #333;
  display: grid;
  grid-template-columns: 35px auto;
}

._3LP3zG-xScneaYJVZwPxjw {
  height: 3em;
  width: 100%;
  margin-top: 1.2em;
  display: grid;
  grid-template-columns: 1fr auto;
  justify-content: center;
}

._26W8fKtxYU_Qks6yxQPFGs {
  float: right;
  height: 2em;
  margin-top: 0.5em;
  justify-self: end;
}

._2TvnNuZntrxW8bqWeIrLjB {
  font-size: 28px;
  font-weight: 550;
  font-style: italic;
  float: left;
  margin: 0;
}
._1iNkHNLNINCdsTvOKVu7gx{
  position: relative;
  bottom: 5px; 
  color:  rgba(0, 0, 0, 0.45);
  font-size: 13px;
  font-weight: 400;
  font-style: italic;
}
._25ZjRg0JkuIoDAfLtln5Mb {
  width: 100%;
  height: 2em;
  font-size: 13px;
  font-weight: 500;
  color: #555;
  font-style: italic;
}

/* Event Process */

._17X8C8BONx4HkwOkU0DHu9 {
  min-height: 20em;
  background-color: #282828;
  padding-bottom: 3em;
}

._15JJlmGwR9bwmwFEX3E9C4 {
  padding-top: 15em;
  height: calc(100vh - (25em));
  min-height: 25em;
}

._3PinJURlYLpp-lR9CPAvy9 {
  display: grid;
  grid-template-columns: 2em auto;
  color: #cfcfcf;
  width: 44em;
  margin: 1em auto;
}

._3CeHzOiQSQQVdkopsRYdfG {
  margin-top: 4px;
  display: inline-block;
}

._2MsLrJuUzYw76irxbEzQh {
  padding-top: 10em;
}

._13XeKQCHOQBws03g1H0ErK {
  display: inline-block;
  word-break: break-word;
}

@media only screen and (max-width: 1000px) {
  .CXnfzeLSd9bivLbILnULm {
    grid-template-areas: "header" "bigImg" "smallImg" "info"; 
    grid-template-columns: 100vw;
    grid-template-rows: auto;
    grid-gap: 2em;
    margin: 0 auto;
    width: 100%;
    margin-bottom: 3em;
  }

  ._1sy89I33zm7r6XFBTMxlzK {
    width: 100vw;
    height: calc(100vw * 0.75);
    grid-area: bigImg;
  }

  ._344zKFNfv5DcoaWZqUhkbb {
    width: 90%;
    margin: 0 auto;
  }

  ._1jqwL5BTvNDLGwDiPW0tyU {
    grid-template-columns: repeat(auto-fill, minmax(8em, 1fr));
    width: 100%;
  }

  ._3PinJURlYLpp-lR9CPAvy9 {
    width: 100%;
  }
}


._3ULE0sswR2ahhhbjfSSHDd{
  text-align: center;
  margin-top: -1.5em;
  font-size: 12px;
  color: rgba(0, 0, 0, 0.45);
  font-size: 13px;
  font-weight: 400;
}