.ctwzaCix_heJW17jshcgz {
  margin: 0 auto;
  display: block;
  width: 66em;
}

@media only screen and (max-width: 1000px) {
  .ctwzaCix_heJW17jshcgz {
    width: 100%;
    padding-top: -2em;
  }
}