._3cVpN6q8v6SFwC7Bg0rxPU {
  min-height: 20em;
  background-color: #5A7B5D;
  padding-bottom: 3em;
}

._24EY4GkWPK9BNoGgZiAJEm {
  display: grid;
  grid-template: "top top""left right";
  grid-gap: 3em;
  width: 53em;
  margin: 0 auto;
}
.WZEMyoFCXO6WMaWkaSPX1 {
  grid-area: top;
  width: 100%;
  margin: 0 auto;
  color: white;
}


._3z9Fhfd7ifFDBLO-AF12qS {
  grid-area: left;
  width: 25em;
  color: white;
}

._3n_2hV-HeocL7g3WwKkVWx {
  grid-area: right;
  width: 25em;
  display: grid;
  grid-gap: 3em;
  grid-template-rows: auto auto auto;
  color: white;
  height: fit-content;
}
._3z9Fhfd7ifFDBLO-AF12qS > div,
._3n_2hV-HeocL7g3WwKkVWx > div {
  color: #BABABA !important;
}

._3z9Fhfd7ifFDBLO-AF12qS > div > div:first-child,
._3n_2hV-HeocL7g3WwKkVWx > div > div:first-child {
  color: white !important;
}

@media only screen and (max-width: 900px) {
  ._24EY4GkWPK9BNoGgZiAJEm {
    align-content: center;
    grid-template: "top" "left" "right";
    width: 92%;
    justify-self: center;
    margin: 0 auto;
  }

  ._3Axbn2thN3WopogKc7gT3k,
  .JoIt0E6A1TW-fqG2wmaoS {
    width: 100%;
    margin: 0 auto;
  }

  ._2U3GG6Mjs8Pyeif0ptsEc {
    font-size: 14px;
  }
}
@media only screen and (max-width: 400px) {
  ._2U3GG6Mjs8Pyeif0ptsEc {
    font-size: 14px;
  }
}
