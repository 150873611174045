._3km7vbEODbjinbSZYcGjQP{
    position: relative;
    width: fit-content;
}

._3km7vbEODbjinbSZYcGjQP{
    background-color: transparent;
    
    width: 284px;
    height: 213px;
    perspective: 1000px;
  }

  .mt7qs2e4OVeTy4nukcM_m{    
    font-size: 230px;
    background-color: #48614a;
    color: #5d735f;
    width: 100%;
  }
  
  ._10qlGbceSBL5y7IDRRfEBp {
    position: relative;
    width: 100%;
    height: 100%;
    text-align: center;
    transition: transform 0.6s;
    transform-style: preserve-3d;
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
  }
  
  ._3km7vbEODbjinbSZYcGjQP:hover ._10qlGbceSBL5y7IDRRfEBp {
    transform: rotateY(180deg);
    cursor: pointer;
  }
  
  ._1VC5cWoiD7kIB4i0nVa8l0, ._3mQZOCqd2ccz9ou4FF7PRx {
    position: absolute;
    width: 100%;
    height: 100%;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    white-space: pre-wrap;
  }
  
  ._1VC5cWoiD7kIB4i0nVa8l0 {
    background-color: #bbb;
    color: black;
    overflow: hidden;
  }
  
  ._3mQZOCqd2ccz9ou4FF7PRx {
    background-color: #415843;
    color: white;
    transform: rotateY(180deg);
  }

  ._3mQZOCqd2ccz9ou4FF7PRx > div:first-child{
    font-weight: 600;
    padding: 1em 0;
    font-size: 16px;
  }

  .Ov2k9nJ1TymYuWq9g0aUB{    
    width: 100%;
    object-fit: fill;
    background-size: cover;
    background-position: center;
  }


