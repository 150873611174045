._2wZu-CNv4pAhfiJz1yd2_G a:link,
._2wZu-CNv4pAhfiJz1yd2_G a:visited,
._1e8xPNfI1m-Ol3GiprbB4O {
  color: rgba(0, 0, 0, 0.85);
  font-style: italic;
}

._2wZu-CNv4pAhfiJz1yd2_G a:hover,
._1e8xPNfI1m-Ol3GiprbB4O:hover {
  color: #888;
  border-bottom: 1px solid #888;
  cursor: pointer;
}
