._1EFw1TT3cm5uGWyyF4VyFu {
  position: fixed;
  width: 100%;
  z-index: 1011;
  transition: height 0.4s linear;
  transition: background-color 0.3s linear;
  background-color: #333333b3;
  display: grid;
  justify-content: center;
  height: 139px;
}

._1EFw1TT3cm5uGWyyF4VyFu:hover{  
  background-color: #333;
}


._1uuRgNDoBFRLfNImfRwAkZ{
  width: 152px;
  justify-self: center;
  margin-top: 13px;
}


._1HjrVVHRTV3zuKoxybgFur {
  transform: skew(16deg);
}

._2-IKyfdUh0cEFFAFAbBoKY {
  width: 174px;
  height: 108px;
  transition: height 0.4s linear,width 0.4s linear;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  margin: 0 auto;
}

.UCjb6O3gEexotxPrF3EN { 
  float: right;
  display: inline-flex;
  gap: 2em;
  color: #fff;
}

._39Iip53aFX7SiIagzmSG7e {
  position: absolute;
  right: 0px;
  float: right;
  padding: 34px 13px;
  gap: 1em ;
  justify-content: center;
  display: flex;
}

._3c1RsF7z_MlqnLlP8gNBOt{
  font-size: 20px;
  text-align: center;
  color: rgb(224, 224, 224);
}

._2ozn7cgK0qBizsYOfPOjpb {  
  border-right: rgba(224, 224, 224, 0.651) solid 2px;
}

._3xacbN39OsGhseHmfr3j0V {
  height: 100vh;
  width: 100vw;
  background-color: rgb(54 54 54 / 93%);
  position: absolute;
  right: 0px;
  top: 0;
  margin-top: 139px;
  padding-top: 24px;
  transition: width 0.4s ease-in-out;
}


._1GI8GJDnRwIjcFYqGOkMey {
  font-size: 25px;
  color: rgb(224, 224, 224);
  transform: skew(-16deg);
  transition: transform 0.4s ease-in-out;
}

._20ZNuNccCqavXKZX3Wxn21 , ._38Egmuyfn8sswGVmNGsKqe,  ._35POQ8aFb8_YtkNm3a11YO{
  color: rgb(169 169 169);
  margin: 0 auto;
  font-size: 18px;
  font-weight: 550;
  width: 90%;
}

._38Egmuyfn8sswGVmNGsKqe{
  padding-left: 2em;
}


._1FIH50xWqfIV0oypWq5UV5 {
  border-top-color: #4e4e4eb5;
}
._20ZNuNccCqavXKZX3Wxn21:hover , ._38Egmuyfn8sswGVmNGsKqe:hover, ._35POQ8aFb8_YtkNm3a11YO{
  color: white;
  cursor: pointer;
}


@media only screen and (max-width: 999px) {
  ._1EFw1TT3cm5uGWyyF4VyFu {
    justify-content: left;
  }

  
}


._3LvGzejVXGTTM9lXtRMPxz,
._1bMfHhUS8qgx8NIamxMA1f{
    padding: 0 1em 0.6em;
    color: rgb(224, 224, 224);
}

._3LvGzejVXGTTM9lXtRMPxz:hover,
._1bMfHhUS8qgx8NIamxMA1f:hover{
    color: white;
    cursor: pointer;
    border-bottom: 1.5px white solid;
}

._1bMfHhUS8qgx8NIamxMA1f{
  color: white;
  border-bottom: 1.5px white solid;
}

._3C-mjQBjQ50GHYRce9aZUJ{
  position: absolute;
  right: 1em;
  display: flex;
  gap: 1em;
  font-size: 20px;
  height: 31.3906px;
  overflow: hidden;
}

.cci-LtDqkFy3QnXlXSpJV{
  background-color: #333333b3 ; 
  color: rgb(224, 224, 224) !important;
  margin-top: -4px;

}

._2GmYDKO-wGhWG60Rtji8YW{
  color: rgb(224, 224, 224)  !important;
  
}

._2GmYDKO-wGhWG60Rtji8YW:hover{  
  background-color: unset !important; 
  color: white !important;
  cursor: pointer;
}