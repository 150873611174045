._1chHB1_LbPTXTOl2kZH0CZ {
  width: 100%;
}

.eiKTvhORyZmZ0Ad6IY2RP {
  width: 100%;
  margin: 0 2em;
}

.hvJyEIxY38Fge-4DFcr3V {
  padding-top: 4em;
}

._1chHB1_LbPTXTOl2kZH0CZ > tr > td:nth-child(2),
.eiKTvhORyZmZ0Ad6IY2RP > tr > td:nth-child(2),
.eiKTvhORyZmZ0Ad6IY2RP > tr > th:nth-child(2) {
  text-align: end;
  width: fit-content;
}
._1chHB1_LbPTXTOl2kZH0CZ > tr > td:nth-child(1) {
  font-weight: 550;
}

.eiKTvhORyZmZ0Ad6IY2RP > tr > th:nth-child(1) {
  text-align: start;
}

.eiKTvhORyZmZ0Ad6IY2RP > tr > th {
  font-weight: 500;
  font-style: italic;
  border-bottom: 1px solid rgba(0, 0, 0, 0.06);
}

._3jsUSEv3d1gZCItcHrI8zl {
  font-size: 12px;
  margin-top: 1em;
}
