
.udZhZXeLYNCZXvfvwq6d-{
        margin-top: 1em;
}

.udZhZXeLYNCZXvfvwq6d- tr td {
        vertical-align: top;
        text-align: left;
        padding-right: 3em;
}
