._1HVNc47QCaP1NRaMOYrNxh {
  min-height: 20em;
  background-color: #282828;
  padding-bottom: 3em;
}

._1dhCgvkvoQlLnPrFVI--3v {
  display: grid;
  grid-template: "left right";
  grid-gap: 3em;
}

._2JS0xhW8xj_EYsqY2IkKNE {
  grid-area: left;
  width: 25em;
  justify-self: end;
  color: white;
}

.EMWBJdiTLEak4nCbLXTu0 {
  grid-area: right;
  width: 25em;
  color: white;
}

.GVHCFEVuU-BtH7AemLqRZ{
  color: #888;
  font-size: 16px;
  text-decoration: underline;
  border-bottom: 1px solid #888;
  text-align: center;
}

.GVHCFEVuU-BtH7AemLqRZ:hover{
  color: rgb(207, 207, 207);
  text-decoration: underline;
  border-bottom: 1px solid rgb(207, 207, 207);
}

@media only screen and (max-width: 900px) {
  ._1dhCgvkvoQlLnPrFVI--3v {
    align-content: center;
    grid-template: "left" "right";
    width: 92%;
    justify-self: center;
    margin: 0 auto;
  }

  .EMWBJdiTLEak4nCbLXTu0,
  ._2JS0xhW8xj_EYsqY2IkKNE {
    width: 100%;
    margin: 0 auto;
  }

  ._3SkOnICz-PKusKXTrCIae8 {
    font-size: 14px;
  }
}
@media only screen and (max-width: 400px) {
  ._3SkOnICz-PKusKXTrCIae8 {
    font-size: 14px;
  }
}
