._2Gagq_IHkJbchg8q3b6Yp8 {
  min-height: 20em;
  background-color: #282828;
  padding-bottom: 3em;
}

._2BV33QrYj5ApjEEp9RYdpp {
  height: fit-content;
  min-height: 10em;
  padding-bottom: 2em;
}

.DCgtZQrMJqN44C2KUX4Wl {
  color: #747474;
}

._3kTdIPQbtnSKUue4sGHT20 {
  color: #747474;
  text-align: center;
  margin-bottom: 3em;
  white-space: break-spaces;
}

.mpIgvRFE9nvo65_WlDsSS {
  color: white;
  font-size: 30px;
  width: 100%;
  position: relative;
  margin: 0px auto;
  text-align: center;
  font-style: italic;
  padding-top: 1em;
}

