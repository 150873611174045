.nQtQe4-hK6aeTzVFdKh66 {
  width: 100%;
  align-content: center;
  display: grid;
  grid-template-areas: "img info";
  grid-template-columns: 28em 41em;
  grid-gap: 1em;
  padding: 3em 0;
  width: 62em;
  margin: 0 auto;
}

._2qcIQdrBQENv2NJitdLz7t {
  object-fit: cover;
  width: 28em;
  height: 21em;
  position: relative;
  margin: 0 auto;
  grid-area: img;
}

._1ew4hcuOANCIzy1sMRUXoy {
  width: 28em;
  justify-self: end;
}

._3gEluzEuqSFqkMH7EqiQnZ {
  object-fit: cover;
  width: 28em;
  height: 21em;
}

.I5mtwEbxcthyaAMlOPsF_ {
  width: 37em;
  position: relative;
  grid-area: info;
  height: 20em;
}

.fZtPPkX4db1cUH55mPI7C{  
  white-space: pre-wrap;
}

@media only screen and (max-width: 1000px) {
  .nQtQe4-hK6aeTzVFdKh66 {
    grid-template-areas: "img" "info";
    grid-template-columns: 100vw;
    width: 100vw;
  }

  ._1ew4hcuOANCIzy1sMRUXoy,
  ._3gEluzEuqSFqkMH7EqiQnZ {
    width: 100vw;
    height: calc(100vw * 0.75);
  }

  .I5mtwEbxcthyaAMlOPsF_ {
    width: 90%;
    margin: 0 auto;
  }
}
