._1G9cj5ZsnYwSH3_dnTCMFe {
  object-fit: cover;
  width: 100%;
  height: 100%;
  position: relative;
  margin: 0 auto;
  grid-area: img;
  background-size: cover;
  background-position: center;
  background-color: black;
}

._3Ikw-xHNsgP809p6uIy4s_ {
  width: 41em;
  height: 41em;
}

.YTEi8WL2IU069tR1RU_Rw {
  position: absolute;
  z-index: 2;
  top: 48%;
  font-size: 30px;
  color: #ffffff7d;
}

.YTEi8WL2IU069tR1RU_Rw:hover {
  color: #fff;
}

._99yzrW45-aRJK4O-k9255 {
  left: 0;
}

._1kMf17HAGa13d0GY4MJ2CW {
  right: 0;
}

._2W_X1RHAM3T3q7A2W0WKJC {
  height: 100%;
  width: 100%;
}
