._1uIsXgTk44rr7snxwXW1lL{
  padding-top: 9em;
}

._3nJD5KK2taLYkEqVQBZisV{
    max-width: 66em;
    margin: 0 auto;
    margin-bottom: 3em;    
    white-space: pre-wrap;
    display: grid;
    grid-template-areas: "desc ad" "price ad";
    grid-template-columns: auto 20em;
    gap: 2em;
}

._3nJD5KK2taLYkEqVQBZisV > a:link,
._3nJD5KK2taLYkEqVQBZisV > a:visited,
.r-sYt4dWSrLQzVOhDD4wZ > a:visited,
.r-sYt4dWSrLQzVOhDD4wZ > a:link{
  color: #282828;
  border-bottom: 1px solid #282828;
} 
._3nJD5KK2taLYkEqVQBZisV a:hover{
  color: rgb(207, 207, 207);
  border-bottom: 1px solid rgb(207, 207, 207);
}

._1_9VUKrFZv0Yrjf4hUbIkx,._3nJD5KK2taLYkEqVQBZisV,._2d8Y8gZl29j6aEZyjWbGY_{

  padding: 0em 2em;
}

._1_9VUKrFZv0Yrjf4hUbIkx{    
    min-height: 20em;
    background-color: #5A7B5D;
    padding-bottom: 3em;   
    color: white; 
    
}

._3RNCzQ2GvPxQ2F8WgZz4-P{
    max-width: 66em;
    margin: 0 auto;
}

._1Nk2BH-FkVvJqO-ScM99Ix{
  
  margin: 0 auto;
  width: fit-content;
  display: grid;
  grid-template-columns: 1fr  1fr;
  grid-gap: 1em;
}



._2henZJ6zJmRZ1SLzjfv1k7{
  margin: 0 auto;
  width: fit-content;
  padding-bottom: 3em;
  max-width: 60em;
}

._2t0BsoXMKMPMeIXXLBWEHO {
    display: grid;
    grid-template: "left right";
    grid-template-columns: 1fr 1fr;
    grid-gap: 3em;
    max-width: 50em;
    margin: 0 auto;
  }
  
  ._3c1R7gkRuzB9WW2hXcU8Px {
    grid-area: left;
    color: white;
  }
  
  ._1cm22vyUdX6Yv7P5QNKZrN {
    grid-area: right;
    color: white;
  }
  
  ._2ORWMLy-KRfPrHcH-8g1vV tr td {
    vertical-align: top;
    text-align: left;
    color: #282828; 
}

._2ORWMLy-KRfPrHcH-8g1vV tr:first-child td {
  font-weight: 550;
  color: rgb(51, 51, 51);
  padding-bottom: 0.5em;
  min-width: 210px;
}

.egaWjiCqMxnfXD6VQQHYz {
  margin-top: 4px;
  display: inline-block;
}

._3IVL7VWiIOLhEHMbDIDdZV{
  margin-bottom: 1em;
}

._3IVL7VWiIOLhEHMbDIDdZV tr td{
    vertical-align: top;
    text-align: left;
    padding-right: 3em;    
    color: #cfcfcf; 
}
._3IVL7VWiIOLhEHMbDIDdZV tr:first-child td:first-child{
  padding-top: 0.5em;

}

._1Cr6lrVsemoJupRcGGNeYu{
  width: 25em;
  white-space: pre-wrap;
}

._3009j14VgP-5i79PnmchDU tr td div{
  display: flex;
  margin-top: 0.1em;
}


._2_dW7GVagBRVOqYPYqcVj1{
  margin-left: 2px;
}


._31-acPighgKQzRIoIM32lU{

  width: 100%;
  display: inline-block;
  height: 100%;
  object-fit: cover;
  overflow: hidden;
}

._5SmU1Lwi9xmk2XojouHyl{
  height: 29em;
}

._1OvX9k_rzrofCXDTnZtkv0{
  min-height: 20em;
  grid-area: ad;
  display: grid;
}


._2kl3prvkwA0iK4dG5_Eyxq{
  margin-bottom: 1em;
  margin-top: -0.5em;
}

@media only screen and (max-width: 750px){
  ._3nJD5KK2taLYkEqVQBZisV{
    grid-template-areas: "desc" "ad" "price";
    grid-template-columns: auto;
  }

  ._1Nk2BH-FkVvJqO-ScM99Ix{
    grid-template-columns: 1fr;
  } 
  
._3IVL7VWiIOLhEHMbDIDdZV tr td:nth-child(2){
  width: 70px;
  margin-right: 0.5em;
}
._3IVL7VWiIOLhEHMbDIDdZV tr td{
  padding-right: 2em;  
}
}

@media only screen and (max-width: 600px){
  ._2t0BsoXMKMPMeIXXLBWEHO{
    grid-template: "left" "right";
  }
  ._3c1R7gkRuzB9WW2hXcU8Px{
    justify-self: unset;
  }

  
}


.Gb-f61i-f8zrzCUjwGOgu{
  position: relative;
  max-width: 36em;
  margin: 0 auto;
  margin-top: -3.3em;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 2em;
}


._1XIGiPQaiDgNmbKW04rUBt:hover {
  cursor: pointer;
  background-color: white;
}

._1XIGiPQaiDgNmbKW04rUBt {
  padding: 0.2em 1.5em;
  transform: skew(-16deg);
  height: 3.4em;
  background-color: #cfcfcf75;
}

.f4h2Sylb-W4nrV_X_fAV7 {
  background-color: white;
}

.f4h2Sylb-W4nrV_X_fAV7:hover{

  background-color: white;
}

._1x_4iVNirXLfZnthtDcYC5 {
  font-weight: 500;
  font-size: 18px;
  color: rgb(51, 51, 51);
  text-align: center;
  justify-self: center;
  height: 100%;
  width: fit-content;
  margin: 0.4em auto;
  transform: skew(16deg);
}
