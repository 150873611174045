._3r6nrIE6dlwH9ZC-JRkQN- {
  background-color: #333;
  height: 25em;
  width: 100%;
  position: relative;
  color: #888;
  text-align: start;
}

._2BdKtxUJJZq49wt4p7Qjbn {
  position: relative;
  color: #888;
  width: 66em;
  margin: 0 auto;
  display: grid;
  grid-template-areas: "menu service contact contact" "copyright copyright copyright social";
  grid-template-columns: repeat(4, 1fr);
  row-gap: 5em;
  padding: 3em 0;
}

.euszvGgOLgP4T0aIQoUpt {
  grid-column: span 3;
  margin-top: 0.5em;
  grid-area: copyright;
}

._1Dkaxsc05OPAuwvkktL51R {
  color: #888;
  font-size: 30px;
}

._1Dkaxsc05OPAuwvkktL51R:hover {
  color: rgb(207, 207, 207);
  font-size: 30px;
}

._2DYmq6NbxKTUITxEOC6U5O {
  padding: 1em;
  top: 0em;
  right: 0em;
  position: absolute;
  grid-area: social;
}

._1eWyKUn8tmrL8wRc8kGmHc {
  padding: 1em;
  top: 0em;
  left: 0em;
  position: absolute;
}

._3wasukMhAb3t_uAOfCR8x4 a:link,
._3wasukMhAb3t_uAOfCR8x4 a:visited,
.K0rYS9IGgBxdO3BzvIK6j a:link,
.K0rYS9IGgBxdO3BzvIK6j a:visited,
._3PhaUS3UeDJc8zC_pnwqvm a:link,
._3PhaUS3UeDJc8zC_pnwqvm a:visited {
  color: #888;
  border-bottom: 1px solid #888;
}

._3wasukMhAb3t_uAOfCR8x4 a:hover,
.K0rYS9IGgBxdO3BzvIK6j a:hover,
._3PhaUS3UeDJc8zC_pnwqvm a:hover {
  color: rgb(207, 207, 207);
  border-bottom: 1px solid rgb(207, 207, 207);
}

.bNerEoISvbnRgpSP15saL {
  font-weight: 600;
  padding-bottom: 1em;
}

._3wasukMhAb3t_uAOfCR8x4 {
  grid-area: service;
}
.K0rYS9IGgBxdO3BzvIK6j {
  grid-area: menu;
}
._1Euru9_FDCYlDyH07tmHaG {
  grid-area: pay;
}
._3PhaUS3UeDJc8zC_pnwqvm {
  grid-area: contact;
  display: grid;
  grid-template-columns: 1fr 1fr;  
  align-items: end;
}
.E8XDtn2daXRROBME09Ze9 {
  grid-area: social;
}

@media only screen and (max-width: 900px) {
  ._2BdKtxUJJZq49wt4p7Qjbn {
    grid-template-columns: 1fr 1fr;
    grid-template-areas:
      "menu service"
      "contact contact"
      "copyright copyright"
      "social social";
    width: 80%;
    grid-gap: 1em;
    margin: 0 auto;
  }

  ._3r6nrIE6dlwH9ZC-JRkQN- {
    height: 100%;
  }
}

